import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import './WMSChat.css';

const WMSChat = () => {
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [hasAccess, setHasAccess] = useState(false);
    const messagesEndRef = useRef(null);
    const { t } = useTranslation();

    useEffect(() => {
        const checkWMSAccess = async () => {
            try {
                const token = sessionStorage.getItem('access_token');
                const wmsToken = sessionStorage.getItem('wms_token');
                
                console.log('VERIFICATION WMS TOKENS:', { 
                    token: token?.substring(0, 10), 
                    wmsToken: wmsToken?.substring(0, 10),
                    fullWmsToken: wmsToken
                });
                
                if (!token || !wmsToken) {
                    console.error('TOKENS MANQUANTS:', { hasToken: !!token, hasWmsToken: !!wmsToken });
                    setHasAccess(false);
                    return;
                }

                setHasAccess(true);

                try {
                    const response = await axios.get('/api/users/user', {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });
                    console.log('User data:', response.data);
                } catch (userError) {
                    console.error('User check failed but keeping WMS token:', userError);
                }

            } catch (error) {
                console.error('WMS check failed:', error);
                setHasAccess(false);
            }
        };

        checkWMSAccess();

        const interval = setInterval(checkWMSAccess, 5000);
        return () => clearInterval(interval);
    }, []);

    const sendMessage = async (content) => {
        if (!content.trim()) return;

        const wmsToken = sessionStorage.getItem('wms_token');
        console.log('Sending message with WMS token:', wmsToken);

        if (!wmsToken) {
            console.log('No WMS token found in sessionStorage');
            setMessages(prev => [...prev, { 
                content: t('wmsSessionExpired'), 
                type: 'bot' 
            }]);
            return;
        }

        // Add user message to chat
        setMessages(prev => [...prev, { content, type: 'user' }]);
        setNewMessage('');

        try {
            const messageData = {
                content: content,
                agent_type: 'manager'
            };

            console.log('Request payload:', messageData);

            const response = await axios.post('/api/messages/wms/proxy/message', messageData, {
                headers: {
                    'Authorization': `Bearer ${wmsToken}`,
                    'Content-Type': 'application/json',
                },
            });

            console.log('Message response:', response.data);

            setMessages(prev => [...prev, { 
                content: response.data.content, 
                type: 'bot' 
            }]);
        } catch (error) {
            console.error('Failed to send message:', error.response?.data || error);
            
            let errorMessage;
            if (error.response?.status === 401) {
                errorMessage = t('wmsSessionExpired');
                console.log('401 Unauthorized - clearing WMS token');
                sessionStorage.removeItem('wms_token');
                setHasAccess(false);
            } else {
                errorMessage = t('wmsCommunicationError');
            }
            
            setMessages(prev => [...prev, { 
                content: errorMessage, 
                type: 'bot' 
            }]);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        sendMessage(newMessage);
    };

    return (
        <div className="wms-chat-container">
            <div className="wms-chat-messages">
                {messages.length === 0 && (
                    <div className="wms-chat-message bot">
                        {t('welcomeMessage', 'How can I help you today?')}
                    </div>
                )}
                {messages.map((message, index) => (
                    <div key={index} className={`wms-chat-message ${message.type}`}>
                        {message.content}
                    </div>
                ))}
                <div ref={messagesEndRef} />
            </div>
            
            <form onSubmit={handleSubmit} className="wms-chat-input">
                <input
                    type="text"
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    placeholder={t('typeMessage')}
                />
                <button type="submit" className="wms-chat-send-button">
                    {t('send')}
                </button>
            </form>
        </div>
    );
};

export default WMSChat;
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaTruck, FaEdit } from 'react-icons/fa';
import './CarriersConversation.css';
import axios from 'axios';

const CarriersConversation = ({ conversation, onValidateMessage, isValidating, onRefresh }) => {
    const { t } = useTranslation();
    const [isEditing, setIsEditing] = useState(false);
    const [editedParams, setEditedParams] = useState({});
    const [editedMessage, setEditedMessage] = useState('');

    if (!conversation) return null;

    const formatTimestamp = (timestamp) => {
        return new Date(timestamp).toLocaleString();
    };

    const getCarrierClass = (carrierName) => {
        const carrier = carrierName?.toLowerCase().replace(/\s+/g, '-') || '';
        return `carrier-${carrier}`;
    };

    const messages = conversation.messages || (conversation.status === 'pending_validation' ? [{
        direction: 'sent',
        content: conversation.interaction_params?.message || conversation.interaction_params?.comment || '',
        timestamp: conversation.created_at,
        status: 'pending'
    }] : []);

    const handleEditClick = () => {
        setIsEditing(true);
        setEditedParams(conversation.interaction_params || {});
        setEditedMessage(messages[0]?.content || '');
    };

    const handleSaveEdit = async () => {
        try {
            const token = sessionStorage.getItem('access_token');
            await axios.put(
                `/api/tickets/carriers_conversation/${conversation.ticket_id}/update`,
                {
                    interaction_params: editedParams,
                    first_message: editedMessage
                },
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );
            setIsEditing(false);
            // Trigger a refresh of the conversation data
            if (onRefresh) {
                onRefresh();
            }
        } catch (error) {
            console.error('Failed to update carriers conversation:', error);
        }
    };

    const handleCancelEdit = () => {
        setIsEditing(false);
        setEditedParams(conversation.interaction_params || {});
        setEditedMessage(messages[0]?.content || '');
    };

    const parseCarrierResponse = (content) => {
        if (!content) return { details: '' };
        
        // Check if this is a carrier response (contains "Latest update from")
        if (content.includes('Latest update from')) {
            const detailsMatch = content.match(/Details:\s*(.*?)$/);
            return {
                details: detailsMatch ? detailsMatch[1].trim() : content
            };
        }
        
        // If it's not a carrier response, return the full content as details
        return { details: content };
    };

    return (
        <div className="carriers-conversation">
            {(conversation.tracking_id || conversation.carrier_name) && (
                <div className="carriers-conversation-tracking">
                    {conversation.carrier_name && (
                        <span className={`tracking-badge carrier ${getCarrierClass(conversation.carrier_name)}`}>
                            {conversation.carrier_name}
                        </span>
                    )}
                    {conversation.tracking_id && (
                        <span className="tracking-badge">
                            {t('carriersConversation.trackingId')}: {conversation.tracking_id}
                        </span>
                    )}
                </div>
            )}
            
            <div className="carriers-conversation-content">
                {conversation.interaction_params && (
                    <div className="carriers-conversation-params">
                        {isEditing ? (
                            <>
                                {Object.entries(editedParams).map(([key, value]) => (
                                    <div key={key} className="param-item">
                                        <span className="param-label">{t(`carriersConversation.${key}`)}:</span>
                                        <input
                                            type="text"
                                            value={value}
                                            onChange={(e) => setEditedParams({
                                                ...editedParams,
                                                [key]: e.target.value
                                            })}
                                            className="param-input"
                                        />
                                    </div>
                                ))}
                                <div className="edit-actions">
                                    <button onClick={handleSaveEdit} className="edit-button save">
                                        {t('carriersConversation.save')}
                                    </button>
                                    <button onClick={handleCancelEdit} className="edit-button cancel">
                                        {t('carriersConversation.cancel')}
                                    </button>
                                </div>
                            </>
                        ) : (
                            <div className="params-content">
                                {conversation.interaction_params.option_text && (
                                    <div className="param-item">
                                        <span className="param-label">{t('carriersConversation.option')}:</span>
                                        <span className="param-value">{conversation.interaction_params.option_text}</span>
                                    </div>
                                )}
                                {conversation.interaction_params.tracking_id && (
                                    <div className="param-item">
                                        <span className="param-label">{t('carriersConversation.trackingId')}:</span>
                                        <span className="param-value">{conversation.interaction_params.tracking_id}</span>
                                    </div>
                                )}
                                {conversation.interaction_params.price && (
                                    <div className="param-item">
                                        <span className="param-label">{t('carriersConversation.price')}:</span>
                                        <span className="param-value">{conversation.interaction_params.price}</span>
                                    </div>
                                )}
                                {conversation.interaction_params.file_path && (
                                    <div className="param-item">
                                        <span className="param-label">{t('carriersConversation.filePath')}:</span>
                                        <span className="param-value">{conversation.interaction_params.file_path}</span>
                                    </div>
                                )}
                                {conversation.interaction_params.motif_text && (
                                    <div className="param-item">
                                        <span className="param-label">{t('carriersConversation.motif')}:</span>
                                        <span className="param-value">{conversation.interaction_params.motif_text}</span>
                                    </div>
                                )}
                                {conversation.interaction_params.tracking_number && (
                                    <div className="param-item">
                                        <span className="param-label">{t('carriersConversation.trackingNumber')}:</span>
                                        <span className="param-value">{conversation.interaction_params.tracking_number}</span>
                                    </div>
                                )}
                                {conversation.interaction_params.comments && (
                                    <div className="param-item">
                                        <span className="param-label">{t('carriersConversation.comments')}:</span>
                                        <span className="param-value">{conversation.interaction_params.comments}</span>
                                    </div>
                                )}
                                {conversation.interaction_params.colis_content && (
                                    <div className="param-item">
                                        <span className="param-label">{t('carriersConversation.colisContent')}:</span>
                                        <span className="param-value">{conversation.interaction_params.colis_content}</span>
                                    </div>
                                )}
                                {conversation.interaction_params.colis_value && (
                                    <div className="param-item">
                                        <span className="param-label">{t('carriersConversation.colisValue')}:</span>
                                        <span className="param-value">{conversation.interaction_params.colis_value}</span>
                                    </div>
                                )}
                                {conversation.interaction_params.package_value && (
                                    <div className="param-item">
                                        <span className="param-label">{t('carriersConversation.packageValue')}:</span>
                                        <span className="param-value">{conversation.interaction_params.package_value}</span>
                                    </div>
                                )}
                                {conversation.interaction_params.objet && (
                                    <div className="param-item">
                                        <span className="param-label">{t('carriersConversation.objet')}:</span>
                                        <span className="param-value">{conversation.interaction_params.objet}</span>
                                    </div>
                                )}
                                {conversation.interaction_params.package_content && (
                                    <div className="param-item">
                                        <span className="param-label">{t('carriersConversation.packageContent')}:</span>
                                        <span className="param-value">{conversation.interaction_params.package_content}</span>
                                    </div>
                                )}
                                {conversation.interaction_params.product_family && (
                                    <div className="param-item">
                                        <span className="param-label">{t('carriersConversation.productFamily')}:</span>
                                        <span className="param-value">{conversation.interaction_params.product_family}</span>
                                    </div>
                                )}
                                {conversation.interaction_params.comment && (
                                    <div className="param-item">
                                        <span className="param-label">{t('carriersConversation.comment')}:</span>
                                        <span className="param-value">{conversation.interaction_params.comment}</span>
                                    </div>
                                )}
                                {conversation.status === 'pending_validation' && (
                                    <button onClick={handleEditClick} className="edit-button">
                                        <FaEdit /> {t('carriersConversation.edit')}
                                    </button>
                                )}
                            </div>
                        )}
                    </div>
                )}

                <div className="carriers-conversation-messages">
                    {messages.map((message, index) => (
                        <div key={index} className={`carriers-conversation-message carriers-conversation-message--${message.direction}`}>
                            <div className="carriers-conversation-message__sender">
                                {message.direction === 'received' ? t('carriersConversation.carrier') : t('carriersConversation.us')}
                            </div>
                            <div className="carriers-conversation-message__content">
                                {isEditing && index === 0 ? (
                                    <textarea
                                        value={editedMessage}
                                        onChange={(e) => setEditedMessage(e.target.value)}
                                        className="message-edit-textarea"
                                    />
                                ) : (
                                    <div className="carrier-response">
                                        {message.direction === 'received' ? (
                                            <div className="carrier-response__details">
                                                <div className="carrier-response__details-label">
                                                    {t('carriersConversation.details')}:
                                                </div>
                                                <div className="carrier-response__details-content">
                                                    {parseCarrierResponse(message.content).details}
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="carrier-response__details-content">
                                                {message.content}
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                            <div className="carriers-conversation-message__meta">
                                <span className="carriers-conversation-message__timestamp">
                                    {formatTimestamp(message.timestamp)}
                                </span>
                                <span className="carriers-conversation-message__status">
                                    {t(`carriersConversation.status.${message.status || conversation.status}`)}
                                </span>
                            </div>
                            {(message.status === 'pending' || conversation.status === 'pending_validation') && 
                             message.direction !== 'received' && (
                                <div className="carriers-conversation-message__validation">
                                    <button 
                                        className="carriers-conversation-message__button carriers-conversation-message__button--approve"
                                        onClick={() => onValidateMessage(conversation._id, true)}
                                        disabled={isValidating}
                                    >
                                        {t('carriersConversation.approve')}
                                    </button>
                                    <button 
                                        className="carriers-conversation-message__button carriers-conversation-message__button--reject"
                                        onClick={() => onValidateMessage(conversation._id, false)}
                                        disabled={isValidating}
                                    >
                                        {t('carriersConversation.reject')}
                                    </button>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default CarriersConversation;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Pie, Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
} from 'chart.js';
import {
  FaBoxOpen,
  FaWarehouse,
  FaChartBar,
  FaDollarSign,
  FaClock,
} from 'react-icons/fa';
import './SentimentStatistics.css';

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement
);

const CHART_COLORS = {
  sentiment: {
    line: '#00ff9d', // neon green
    background: 'rgba(0, 255, 157, 0.1)',
  },
  resolved: {
    line: '#00ffff', // neon cyan
    background: 'rgba(0, 255, 255, 0.1)',
  },
  aiResolved: {
    line: '#ff00ff', // neon magenta
    background: 'rgba(255, 0, 255, 0.1)',
  }
};

const PIE_COLORS = [
  '#00ff9d', // neon green
  '#00ffff', // neon cyan
  '#ff00ff', // neon magenta
  '#8000ff', // neon purple
  '#ffff00', // neon yellow
  '#ff0000', // neon red
  '#0099ff', // neon blue
];

const UserStatisticsPanel = ({ userRole }) => {
  const { t } = useTranslation();

  // State variables for sentiment and resolved ticket statistics
  const [sentimentStats, setSentimentStats] = useState(null);
  const [isSentimentLoading, setIsSentimentLoading] = useState(true);
  const [sentimentError, setSentimentError] = useState(null);

  // State variables for user statistics
  const [stats, setStats] = useState(null);
  const [isLoading, setIsLoading] = useState(false); // Initially not loading
  const [error, setError] = useState(null);

  // State for selected period in sentiment statistics
  const [selectedPeriod, setSelectedPeriod] = useState('7_days');

  // Add new state for tags statistics
  const [tagsStats, setTagsStats] = useState(null);

  useEffect(() => {
    fetchSentimentStatistics();
    fetchTagsStatistics();
  }, []);

  const fetchSentimentStatistics = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      const response = await axios.get('/api/tickets/tickets/sentiment-statistics', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setSentimentStats(response.data);
      setIsSentimentLoading(false);
      // Start fetching user statistics after sentiments are loaded
      fetchStatistics();
    } catch (error) {
      console.error('Error fetching sentiment statistics:', error);
      setSentimentError(t('userStatistics.fetchError'));
      setIsSentimentLoading(false);
      // Even if there's an error, attempt to fetch user statistics
      fetchStatistics();
    }
  };

  const fetchStatistics = async () => {
    setIsLoading(true);
    try {
      const token = sessionStorage.getItem('access_token');
      const response = await axios.get('/api/logistics/user/statistics', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setStats(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching user statistics:', error);
      setError(t('userStatistics.fetchError'));
      setIsLoading(false);
    }
  };

  // Add new fetch function for tags
  const fetchTagsStatistics = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      const response = await axios.get('/api/tickets/tickets-tags-stats', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setTagsStats(response.data);
    } catch (error) {
      console.error('Error fetching tags statistics:', error);
    }
  };

  // Loading and error handling for sentiment statistics
  if (isSentimentLoading)
    return <div className="loading">{t('userStatistics.loading')}</div>;

  // Prepare data for sentiment statistics chart
  const sentimentData = sentimentStats?.sentiment_statistics[selectedPeriod];
  let sentimentChartData = null;
  if (sentimentData) {
    const labels = sentimentData.raw.map((item) => item._id);
    const averageSentiments = sentimentData.raw.map(
      (item) => item.average_sentiment
    );

    sentimentChartData = {
      labels: labels,
      datasets: [{
        label: t('sentimentStatistics.averageSentiment'),
        data: averageSentiments,
        borderColor: CHART_COLORS.sentiment.line,
        backgroundColor: CHART_COLORS.sentiment.background,
        borderWidth: 2,
        pointRadius: 4,
        pointHoverRadius: 6,
        tension: 0.4,
        fill: true
      }]
    };
  }

  // Prepare data for resolved tickets statistics chart
  const resolvedData = sentimentStats?.resolved_statistics[selectedPeriod];
  let resolvedChartData = null;
  if (resolvedData) {
    const labels = resolvedData.raw.map((item) => item._id);
    const resolvedCounts = resolvedData.raw.map((item) => item.resolved);
    const aiResolvedCounts = resolvedData.raw.map((item) => item.ai_resolved);

    resolvedChartData = {
      labels: labels,
      datasets: [
        {
          label: t('sentimentStatistics.resolvedTickets'),
          data: resolvedCounts,
          borderColor: CHART_COLORS.resolved.line,
          backgroundColor: CHART_COLORS.resolved.background,
          borderWidth: 2,
          pointRadius: 4,
          pointHoverRadius: 6,
          tension: 0.4,
          fill: true
        },
        {
          label: t('sentimentStatistics.aiResolvedTickets'),
          data: aiResolvedCounts,
          borderColor: CHART_COLORS.aiResolved.line,
          backgroundColor: CHART_COLORS.aiResolved.background,
          borderWidth: 2,
          pointRadius: 4,
          pointHoverRadius: 6,
          tension: 0.4,
          fill: true
        }
      ]
    };
  }

  // Add tags chart data preparation
  let tagsChartData = null;
  if (tagsStats?.tags_statistics) {
    tagsChartData = {
      labels: tagsStats.tags_statistics.map(item => item._id),
      datasets: [{
        data: tagsStats.tags_statistics.map(item => item.percentage),
        backgroundColor: PIE_COLORS,
      }],
    };
  }

  const chartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    interaction: {
      intersect: false,
      mode: 'index'
    },
    plugins: {
      legend: {
        labels: {
          color: '#fff',
          padding: 20,
          font: {
            size: 13,
            family: "'Roboto', sans-serif"
          },
          usePointStyle: true,
          filter: function(item) {
            return !item.text.includes('Background');
          }
        }
      },
      tooltip: {
        backgroundColor: 'rgba(13, 14, 26, 0.95)',
        titleColor: '#fff',
        bodyColor: '#fff',
        padding: 12,
        boxPadding: 8,
        cornerRadius: 8,
        displayColors: true,
        borderColor: 'rgba(0, 255, 157, 0.1)',
        borderWidth: 1,
      }
    },
    scales: {
      x: {
        grid: {
          display: false
        },
        ticks: {
          color: 'rgba(255, 255, 255, 0.7)',
          maxRotation: 45,
          minRotation: 45
        },
        border: {
          display: false
        }
      },
      y: {
        grid: {
          color: 'rgba(255, 255, 255, 0.03)',
          drawBorder: false,
          lineWidth: 1
        },
        ticks: {
          color: 'rgba(255, 255, 255, 0.7)',
          padding: 10,
          maxTicksLimit: 5
        },
        border: {
          display: false
        },
        beginAtZero: true
      }
    }
  };

  return (
    <div className="user-statistics-panel">
      {/* Sentiment and Resolved Tickets Statistics */}
      {sentimentError && (
        <div className="error-message">{sentimentError}</div>
      )}
      {sentimentStats ? (
        <>
          {/* Period Selector */}
          <div className="period-selector">
            {['7_days', '14_days', '30_days', 'monthly'].map((period) => (
              <button
                key={period}
                className={selectedPeriod === period ? 'active' : ''}
                onClick={() => setSelectedPeriod(period)}
              >
                {t(`sentimentStatistics.period.${period}`)}
              </button>
            ))}
          </div>

          <div className="charts-container">
            <div className="line-charts">
              {/* Sentiment Statistics Chart */}
              {sentimentChartData && (
                <div className="sentiment-statistics-section">
                  <h3>{t('sentimentStatistics.sentimentTitle')}</h3>
                  <div className="chart-container">
                    <Line data={sentimentChartData} options={chartOptions} />
                  </div>
                </div>
              )}

              {/* Resolved Tickets Statistics Chart */}
              {resolvedChartData && (
                <div className="resolved-statistics-section">
                  <h3>{t('sentimentStatistics.resolvedTitle')}</h3>
                  <div className="chart-container">
                    <Line data={resolvedChartData} options={chartOptions} />
                  </div>
                </div>
              )}
            </div>

            {/* Tags Statistics Pie Chart */}
            {tagsChartData && (
              <div className="tags-statistics-section">
                <h3>{t('sentimentStatistics.tagsTitle')}</h3>
                <div className="pie-chart-container">
                  <Pie
                    data={tagsChartData}
                    options={{
                      maintainAspectRatio: false,
                      responsive: true,
                      plugins: {
                        legend: {
                          position: 'right',
                          labels: {
                            boxWidth: 12,
                          },
                        },
                        tooltip: {
                          callbacks: {
                            label: (context) => {
                              const label = context.label || '';
                              const value = context.raw || 0;
                              return `${label}: ${value.toFixed(1)}%`;
                            },
                          },
                        },
                      },
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </>
      ) : null}

      {/* Loading message for user statistics */}
      {isLoading && (
        <div className="loading">{t('userStatistics.loadingOtherStats')}</div>
      )}

      {/* User Statistics */}
      {error && <div className="error-message">{error}</div>}
      {stats && !isLoading && (userRole === 'logistics' || userRole === 'e-commerce') ? (
        <>
          {/* Stat Cards */}
          <div className="stat-cards">
            <StatCard
              title={t('userStatistics.totalOrders')}
              value={(stats.total_orders || 0).toLocaleString()}
              icon={<FaBoxOpen />}
            />
            <StatCard
              title={t('userStatistics.totalRevenue')}
              value={`$${(stats.total_revenue || 0).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`}
              icon={<FaDollarSign />}
            />
            <StatCard
              title={t('userStatistics.averageOrderValue')}
              value={`$${(stats.average_order_value || 0).toLocaleString(
                undefined,
                {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }
              )}`}
              icon={<FaDollarSign />}
            />
            <StatCard
              title={t('userStatistics.averageProcessingTime')}
              value={`${(
                stats.average_order_processing_time || 0
              ).toFixed(2)} ${t('userStatistics.hours')}`}
              icon={<FaClock />}
            />
            <StatCard
              title={t('userStatistics.warehouseUtilization')}
              value={`${(stats.warehouse_stats.utilization_rate || 0).toFixed(
                2
              )}%`}
              icon={<FaWarehouse />}
            />
          </div>

          {/* Charts */}
          <div className="chart-grid">
            {stats.orders_by_status &&
            stats.orders_by_status.length > 0 ? (
              <ChartCard title={t('userStatistics.ordersByStatus')}>
                <Pie
                  data={{
                    labels: stats.orders_by_status.map(
                      (item) => `${t('userStatistics.status')} ${item.status}`
                    ),
                    datasets: [
                      {
                        data: stats.orders_by_status.map(
                          (item) => item.count
                        ),
                        backgroundColor: PIE_COLORS,
                      },
                    ],
                  }}
                  options={{ maintainAspectRatio: false, responsive: true }}
                />
              </ChartCard>
            ) : (
              <p>{t('userStatistics.noOrdersByStatusData')}</p>
            )}
          </div>

          {/* Picking and Warehouse Statistics */}
          <div className="stats-grid">
            <StatsCard
              title={t('userStatistics.pickingStatistics')}
              stats={[
                {
                  label: t('userStatistics.totalPickings'),
                  value: stats.picking_stats?.total_pickings || 0,
                },
                {
                  label: t('userStatistics.completedPickings'),
                  value: stats.picking_stats?.completed_pickings || 0,
                },
                {
                  label: t('userStatistics.inProgressPickings'),
                  value: stats.picking_stats?.in_progress_pickings || 0,
                },
                {
                  label: t('userStatistics.averagePickingTime'),
                  value: `${(
                    stats.picking_stats?.average_picking_time || 0
                  ).toFixed(2)} ${t('userStatistics.minutes')}`,
                },
              ]}
            />
            <StatsCard
              title={t('userStatistics.warehouseStatistics')}
              stats={[
                {
                  label: t('userStatistics.totalLocations'),
                  value: stats.warehouse_stats?.total_locations || 0,
                },
                {
                  label: t('userStatistics.occupiedLocations'),
                  value: stats.warehouse_stats?.occupied_locations || 0,
                },
                {
                  label: t('userStatistics.utilizationRate'),
                  value: `${(
                    stats.warehouse_stats?.utilization_rate || 0
                  ).toFixed(2)}%`,
                },
              ]}
            />
          </div>

          {/* Inventory Statistics */}
          <div className="inventory-stats">
            <h3 className="section-title">
              {t('userStatistics.inventoryStatistics')}
            </h3>
            <p className="total-value">
              <strong>{t('userStatistics.totalInventoryValue')}:</strong> $
              {(stats.total_inventory_value || 0).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </p>
          </div>
        </>
      ) : null}
    </div>
  );
};

// Components for reusability (kept within the same file)
const StatCard = ({ title, value, icon }) => (
  <div className="stat-card">
    <div className="stat-header">
      <span className="stat-title">{title}</span>
      <span className="stat-icon">{icon}</span>
    </div>
    <div className="stat-value">{value}</div>
  </div>
);

const ChartCard = ({ title, children }) => (
  <div className="chart-card">
    <h3 className="chart-title">{title}</h3>
    <div className="chart-container">{children}</div>
  </div>
);

const StatsCard = ({ title, stats }) => (
  <div className="stats-card">
    <h3 className="stats-title">{title}</h3>
    <div className="stats-list">
      {stats.map((stat, index) => (
        <div key={index} className="stat-item">
          <span className="stat-label">{stat.label}</span>
          <span className="stat-value">{stat.value}</span>
        </div>
      ))}
    </div>
  </div>
);

export default UserStatisticsPanel;
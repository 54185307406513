import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './dashboard.css';
import TicketTableDashboard from './TicketTableDashboard';
import { useTranslation } from 'react-i18next';
import { FaTicketAlt, FaRobot, FaUserClock, FaChartLine } from 'react-icons/fa';

const Dashboard = () => {
    const [user, setUser] = useState(undefined);
    const [tickets, setTickets] = useState([]);
    const [monthlyUsage, setMonthlyUsage] = useState({ tickets_used: 0, ticket_limit: 0, extra_tickets_purchased: 0, remaining_extra_tickets: 0 });
    const [ticketStats, setTicketStats] = useState({
        automated_tickets: 0,
        pending_tickets: 0
    });
    const navigate = useNavigate();
    const { t } = useTranslation();
    const wsRef = useRef(null);
    const reconnectTimeoutRef = useRef(null);
    const [expandedCards, setExpandedCards] = useState({});

    const connectWebSocket = () => {
        const token = sessionStorage.getItem('access_token');
        if (!token) return;

        wsRef.current = new WebSocket(`/api/ws?token=${token}`);
        
        wsRef.current.onopen = (event) => {
            console.log('WebSocket connection established:', event);
            if (reconnectTimeoutRef.current) {
                clearTimeout(reconnectTimeoutRef.current);
                reconnectTimeoutRef.current = null;
            }
        };

        wsRef.current.onerror = (error) => {
            console.error('WebSocket error:', error);
        };

        wsRef.current.onclose = (event) => {
            console.log('WebSocket connection closed:', event);
            console.log('Close code:', event.code);
            console.log('Close reason:', event.reason);
            reconnectTimeoutRef.current = setTimeout(connectWebSocket, 5000);
        };

        wsRef.current.onmessage = (event) => {
            console.log('WebSocket message received:', event.data);
            if (event.data === 'ping') {
                wsRef.current.send('pong');
            } else {
                fetchTickets(token);
            }
        };
    };

    useEffect(() => {
        const token = sessionStorage.getItem('access_token');
        if (!token) {
            navigate('/');
            return;
        }

        fetchUserData(token);
        fetchTickets(token);
        fetchMonthlyUsage(token);
        fetchTicketStats(token);
        connectWebSocket();

        const pingInterval = setInterval(() => {
            if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
                wsRef.current.send('ping');
            }
        }, 30000);

        return () => {
            clearInterval(pingInterval);
            if (reconnectTimeoutRef.current) {
                clearTimeout(reconnectTimeoutRef.current);
            }
            if (wsRef.current) {
                wsRef.current.close();
            }
        };
    }, [navigate]);

    const fetchUserData = async (token) => {
        try {
            const response = await axios.get('/api/users/user', {
                headers: { Authorization: `Bearer ${token}` }
            });
            setUser(response.data);
        } catch (error) {
            console.error('Failed to fetch user data:', error);
            setUser(null);
        }
    };

    const fetchMonthlyUsage = async (token) => {
        try {
            const response = await axios.get('/api/users/monthly-usage', {
                headers: { Authorization: `Bearer ${token}` }
            });
            setMonthlyUsage(response.data || { tickets_used: 0, ticket_limit: 0, extra_tickets_purchased: 0, remaining_extra_tickets: 0 });
        } catch (error) {
            console.error('Failed to fetch monthly usage:', error);
        }
    };

    const fetchTickets = async (token, page = 1, limit = 25) => {
        try {
            const response = await axios.get('/api/tickets/tickets', {
                headers: { Authorization: `Bearer ${token}` },
                params: { page, limit }
            });
            setTickets(response.data.tickets || []);
            // Handle pagination if needed
            // const { current_page, total_pages, total_tickets, has_next, has_previous } = response.data.pagination;
        } catch (error) {
            console.error('Failed to fetch tickets:', error);
        }
    };

    const fetchTicketStats = async (token) => {
        try {
            const response = await axios.get('/api/tickets/stats', {
                headers: { Authorization: `Bearer ${token}` }
            });
            console.log('API response:', response.data);
            setTicketStats(response.data || {
                automated_tickets: 0,
                pending_tickets: 0
            });
        } catch (error) {
            console.error('Failed to fetch ticket stats:', error);
        }
    };

    const handleTicketClick = async (ticketId) => {
        try {
            const token = sessionStorage.getItem('access_token');
            await axios.put(`/api/tickets/${ticketId}/status`, null, {
                headers: { Authorization: `Bearer ${token}` }
            });
            navigate(`/tickets/${ticketId}/messages`);
        } catch (error) {
            console.error('Failed to update ticket status:', error);
        }
    };

    const usagePercentage = monthlyUsage.ticket_limit > 0 ? (monthlyUsage.tickets_used / monthlyUsage.ticket_limit) * 100 : 0;

    const toggleCard = (cardId) => {
        setExpandedCards(prev => ({
            ...prev,
            [cardId]: !prev[cardId]
        }));
    };

    return (
        <div className="una-dashboard-container">
            <div className="una-dashboard-grid">
                {user && user.role === 'admin' && (
                    <>
                        <div 
                            className={`una-dashboard-card una-tickets-card ${expandedCards.tickets ? 'expanded' : ''}`}
                            onClick={() => toggleCard('tickets')}
                        >
                            <div className="una-card-header">
                                <FaTicketAlt />
                                <h3>{t('ticketsOverview')}</h3>
                            </div>
                            <div className="una-card-preview">
                                <span>{ticketStats.remaining_tickets} {t('remaining')}</span>
                                <span className="una-separator">•</span>
                                <span>{ticketStats.automated_tickets} {t('automated')}</span>
                                <span className="una-separator">•</span>
                                <span>{ticketStats.pending_tickets} {t('pending')}</span>
                            </div>
                            <div className="una-card-content">
                                <div className="una-stat-item">
                                    <span className="una-stat-label">{t('remainingTickets')}</span>
                                    <span className="una-stat-value">{ticketStats.remaining_tickets}</span>
                                </div>
                                <div className="una-stat-item">
                                    <span className="una-stat-label">{t('automatedTickets')}</span>
                                    <span className="una-stat-value automated">{ticketStats.automated_tickets}</span>
                                </div>
                                <div className="una-stat-item">
                                    <span className="una-stat-label">{t('pendingTickets')}</span>
                                    <span className="una-stat-value pending">{ticketStats.pending_tickets}</span>
                                </div>
                            </div>
                        </div>

                        <div 
                            className={`una-dashboard-card una-usage-card ${expandedCards.usage ? 'expanded' : ''}`}
                            onClick={() => toggleCard('usage')}
                        >
                            <div className="una-card-header">
                                <FaChartLine />
                                <h3>{t('ticketUsage')}</h3>
                            </div>
                            <div className="una-card-preview">
                                <span>{monthlyUsage.tickets_used}/{monthlyUsage.ticket_limit} {t('used')}</span>
                                <span className="una-separator">•</span>
                                <span>{monthlyUsage.remaining_extra_tickets} {t('extra')}</span>
                            </div>
                            <div className="una-card-content">
                                <div className="una-usage-bar">
                                    <div 
                                        className="una-usage-progress" 
                                        style={{width: `${usagePercentage}%`}}
                                    ></div>
                                </div>
                                <div className="una-usage-stats">
                                    <p>{monthlyUsage.tickets_used}/{monthlyUsage.ticket_limit} {t('ticketsUsed')}</p>
                                    <p><strong>{t('remainingExtraTickets')}:</strong> {monthlyUsage.remaining_extra_tickets}</p>
                                </div>
                            </div>
                        </div>

                        <div 
                            className={`una-dashboard-card una-agent-card ${expandedCards.agent ? 'expanded' : ''}`}
                            onClick={() => toggleCard('agent')}
                        >
                            <div className="una-card-header">
                                <FaRobot />
                                <h3>{t('aiAgentInfo')}</h3>
                            </div>
                            <div className="una-card-preview">
                                <span>{user?.ai_agent_name || t('notAvailable')}</span>
                                <span className="una-separator">•</span>
                                <span>{user?.automation || t('notAvailable')}</span>
                            </div>
                            <div className="una-card-content">
                                <div className="una-info-item">
                                    <span className="una-info-label">{t('aiAgentName')}</span>
                                    <span className="una-info-value">{user?.ai_agent_name || t('notAvailable')}</span>
                                </div>
                                <div className="una-info-item">
                                    <span className="una-info-label">{t('dashboardAutomationStatus')}</span>
                                    <span className="una-info-value">{user?.automation || t('notAvailable')}</span>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>

            <h3 className="una-dashboard-section-title">
                <FaUserClock /> 
                {t('automatedAndPendingTickets')}
            </h3>
            <TicketTableDashboard tickets={tickets} user={user} onTicketClick={handleTicketClick} />
        </div>
    );
};

export default Dashboard;
// src/components/KnowledgeBoard.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './KnowledgeBoard.css';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  FaFile,
  FaPlus,
  FaTrash,
  FaEye,
  FaCheckCircle,
  FaTimesCircle,
  FaSpinner,
  FaExclamationTriangle,
  FaTimes,
  FaLink,
  FaGlobe,
  FaExternalLinkAlt,
  FaRobot
} from 'react-icons/fa';

// Add these components before the main KnowledgeBoard component
const AddResourceModal = ({ handleUpdateModalClose, handleUpdateModalSubmit, newTitle, setNewTitle, newUrl, setNewUrl }) => {
  const { t } = useTranslation();
  return (
    <div className="modalweb">
      <div className="modal-content">
        <button className="close-popup-button" onClick={handleUpdateModalClose}>
          <FaTimes />
        </button>
        <h3>{t('knowledgeBoard.addResource')}</h3>
        <div className="form-group">
          <label htmlFor="title">{t('knowledgeBoard.resourceTitle')}:</label>
          <input
            type="text"
            id="title"
            value={newTitle}
            onChange={(e) => setNewTitle(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="url">{t('knowledgeBoard.resourceUrl')}:</label>
          <input
            type="text"
            id="url"
            value={newUrl}
            onChange={(e) => setNewUrl(e.target.value)}
          />
        </div>
        <div className="modal-actions">
          <button className="primary-button" onClick={handleUpdateModalSubmit}>
            {t('knowledgeBoard.add')}
          </button>
        </div>
      </div>
    </div>
  );
};

const FeedbackOverlay = ({ message, success }) => (
  <div className="modalweb">
    <div className="modal-content feedback-overlay">
      {success ? <FaCheckCircle className="success-icon" /> : <FaTimesCircle className="error-icon" />}
      <p>{message}</p>
    </div>
  </div>
);

const ResourceViewer = ({ url, onClose }) => (
  <div className="modalweb">
    <div className="modal-content resource-viewer">
      <button className="close-popup-button" onClick={onClose}>
        <FaTimes />
      </button>
      <iframe src={url} title="Resource Viewer" />
    </div>
  </div>
);

const UploadStatusItem = ({ filename, status }) => {
  const { t } = useTranslation();
  return (
    <div className="status-item">
      <span className="filename">{filename}</span>
      <span className="status">
        {status.uploading && <><FaSpinner className="spinning-icon" /> {t('knowledgeBoard.uploading')}</>}
        {!status.uploading && status.success && <><FaCheckCircle className="success-icon" /> {t('knowledgeBoard.uploadSuccess')}</>}
        {!status.uploading && !status.success && <><FaTimesCircle className="error-icon" /> {status.error}</>}
      </span>
    </div>
  );
};

const KnowledgeBoard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // States
  const [user, setUser] = useState(undefined);
  const [resources, setResources] = useState([]); // All resources
  const [fileUrl, setFileUrl] = useState('');
  const [overlay, setOverlay] = useState({ show: false, message: '', success: false });
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [newTitle, setNewTitle] = useState('');
  const [newUrl, setNewUrl] = useState('');
  const [uploadStatuses, setUploadStatuses] = useState({});
  const [urlFileUploadStatus, setUrlFileUploadStatus] = useState(null); // State for URL file upload

  // Fetch user data on component mount
  useEffect(() => {
    const token = sessionStorage.getItem('access_token');
    if (!token) {
      navigate('/'); 
      return;
    }

    fetchUserData(token);
  }, [navigate]);

  // Redirect if user data fetch fails
  useEffect(() => {
    if (user === null) {
      navigate('/'); 
    }
  }, [user, navigate]);

  // Fetch resources once user is loaded
  useEffect(() => {
    if (user) {
      const token = sessionStorage.getItem('access_token');
      fetchUserResources(token);
    }
  }, [user]);

  // Function to fetch user data
  const fetchUserData = async (token) => {
    try {
      const response = await axios.get('/api/users/user', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setUser(response.data);
    } catch (error) {
      console.error('Failed to fetch user data:', error);
      setUser(null);
    }
  };

  // Function to fetch all user resources
  const fetchUserResources = async (token) => {
    try {
      console.log('Current origin:', window.location.origin);
      console.log('Making request to:', '/api/files/list');
      const response = await axios.get('/api/files/list', {
        headers: { 
          Authorization: `Bearer ${token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      });
      setResources(response.data.files);
    } catch (error) {
      console.error('Full error object:', error);
      console.error('Request config:', error.config);
      alert(t('knowledgeBoard.fetchResourcesError'));
    }
  };

  // Handle file selection and upload
  const handleFileSelect = async (event) => {
    const selectedFiles = event.target.files;

    if (!selectedFiles || selectedFiles.length === 0) {
      alert(t('knowledgeBoard.selectFiles'));
      return;
    }

    // Initialize upload statuses
    const initialStatuses = {};
    for (let i = 0; i < selectedFiles.length; i++) {
      initialStatuses[selectedFiles[i].name] = { uploading: true, success: false };
    }
    setUploadStatuses((prevStatuses) => ({ ...prevStatuses, ...initialStatuses }));

    const formData = new FormData();
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append('files', selectedFiles[i]);
    }

    try {
      const token = sessionStorage.getItem('access_token');
      const response = await axios.post('/api/files/upload/', formData, {
        headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' },
      });

      // Update upload statuses based on response
      const updatedStatuses = { ...uploadStatuses };
      for (const fileResult of response.data.files) {
        if (fileResult.status === 'success') {
          updatedStatuses[fileResult.filename] = { uploading: false, success: true };
        } else {
          updatedStatuses[fileResult.filename] = { uploading: false, success: false, error: fileResult.message };
        }
      }
      setUploadStatuses(updatedStatuses);
      fetchUserResources(token);
    } catch (error) {
      console.error('Upload failed:', error);
      // Set all upload statuses to failed
      const updatedStatuses = { ...uploadStatuses };
      for (let i = 0; i < selectedFiles.length; i++) {
        updatedStatuses[selectedFiles[i].name] = { uploading: false, success: false, error: t('knowledgeBoard.uploadFailed') };
      }
      setUploadStatuses(updatedStatuses);
    }
  };

  // Handle URL file upload
  const handleUrlFileSelect = async (event) => {
    const selectedFile = event.target.files[0];

    if (!selectedFile) {
      alert(t('knowledgeBoard.selectUrlFile'));
      return;
    }

    setUrlFileUploadStatus({ uploading: true, success: false });

    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      const token = sessionStorage.getItem('access_token');
      const response = await axios.post('/api/files/upload-url-file', formData, {
        headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' },
      });

      setUrlFileUploadStatus({ uploading: false, success: true, message: response.data.message });
      fetchUserResources(token); // Update resources list
    } catch (error) {
      console.error('Upload failed:', error);
      setUrlFileUploadStatus({ uploading: false, success: false, error: t('knowledgeBoard.uploadFailed') });
    }
  };

  // Handle resource deletion
  const handleDeleteResource = async (resourceKey) => {
    try {
      const token = sessionStorage.getItem('access_token');
      await axios.delete(`/api/files/delete/?file_key=${encodeURIComponent(resourceKey)}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      alert(t('knowledgeBoard.deleteSuccess'));
      fetchUserResources(token);
    } catch (error) {
      console.error('Failed to delete resource:', error);
      alert(t('knowledgeBoard.deleteError'));
    }
  };

  // Handle resource viewing
  const fetchAndViewResource = async (resourceKey) => {
    try {
      const token = sessionStorage.getItem('access_token');
      const response = await axios.get(`/api/files/generate-view-url/?file_key=${resourceKey}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setFileUrl(response.data.url);
    } catch (error) {
      console.error('Failed to view resource:', error);
      alert(t('knowledgeBoard.viewResourceError'));
    }
  };

  // Update resources via modal
  const updateResources = async (title, url) => {
    setOverlay({ show: true, message: t('knowledgeBoard.updating'), success: false });

    try {
      const token = sessionStorage.getItem('access_token');
      await axios.put(
        `/api/files/update-web-json-2/?title=${encodeURIComponent(title)}&url=${encodeURIComponent(url)}`,
        null,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setOverlay({ show: true, message: t('knowledgeBoard.updateSuccess'), success: true });
      fetchUserResources(token);
    } catch (error) {
      console.error('Failed to update resources:', error);
      setOverlay({ show: true, message: t('knowledgeBoard.updateError'), success: false });
    } finally {
      setTimeout(() => setOverlay({ show: false, message: '', success: false }), 2000);
    }
  };

  // Open update modal
  const handleOpenUpdateModal = () => setShowUpdateModal(true);

  // Submit update modal
  const handleUpdateModalSubmit = async () => {
    if (newTitle && newUrl) {
      await updateResources(newTitle, newUrl);
      setNewTitle('');
      setNewUrl('');
      setShowUpdateModal(false);
    } else {
      alert(t('knowledgeBoard.fillAllFields'));
    }
  };

  // Close update modal
  const handleUpdateModalClose = () => {
    setShowUpdateModal(false);
    setNewTitle('');
    setNewUrl('');
  };

  // Remove resource entry
  const handleRemoveResourceEntry = async (title) => {
    const confirmRemove = window.confirm(t('knowledgeBoard.confirmRemoveResource'));
    if (!confirmRemove) return;

    const updatedResources = resources.filter((entry) => entry.title !== title);
    try {
      const token = sessionStorage.getItem('access_token');
      await axios.put(
        `/api/files/delete-web-json-2/?title=${encodeURIComponent(title)}`,
        null,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setResources(updatedResources);
      alert(t('knowledgeBoard.removeResourceSuccess'));
    } catch (error) {
      console.error('Failed to remove resource entry:', error);
      alert(t('knowledgeBoard.removeResourceError'));
    }
  };

  // Render based on user state
  if (user === undefined) {
    return (
      <div className="dashboard-container">
        <div className="loading-spinner">
          <FaSpinner className="spinner" /> {t('knowledgeBoard.loading')}
        </div>
      </div>
    );
  }

  if (user === null) {
    return (
      <div className="dashboard-container">
        <div className="error-message">
          <FaExclamationTriangle /> {t('knowledgeBoard.userFetchError')}
        </div>
      </div>
    );
  }

  if (user.subscription_plan === 'free') {
    return (
      <div className="dashboard-container">
        <div className="dashboard-card knowledge-board-no-access">
          <FaExclamationTriangle className="error-icon" />
          <p>{t('knowledgeBoard.noAccessMessage')}</p>
          <button
            onClick={() => navigate('/Payment')}
            className="upgrade-button"
            aria-label={t('knowledgeBoard.upgradeNow')}
          >
            {t('knowledgeBoard.upgradeNow')}
          </button>
        </div>
      </div>
    );
  }

  // Main render
  return (
    <div className="knowledge-board">
      <div className="knowledge-board-header">
        {/* Action buttons as floating circles around the edge */}
        <div className="action-circles">
          <button 
            className="action-circle upload-files"
            onClick={() => document.getElementById('fileInput').click()}
            title={t('knowledgeBoard.uploadFiles')}
          >
            <FaPlus />
          </button>
          <button 
            className="action-circle upload-url"
            onClick={() => document.getElementById('urlFileInput').click()}
            title={t('knowledgeBoard.uploadUrlFile')}
          >
            <FaLink />
          </button>
          <button 
            className="action-circle add-resource"
            onClick={handleOpenUpdateModal}
            title={t('knowledgeBoard.addResource')}
          >
            <FaGlobe />
          </button>
        </div>
      </div>

      {/* Knowledge Graph Visualization */}
      <div className="knowledge-graph">
        <div className="central-node">
          <FaRobot className="ai-icon" />
          <span>{t('knowledgeBoard.aiKnowledge')}</span>
        </div>
        
        {/* Resources as connected nodes */}
        <div className="knowledge-nodes">
          {resources.map((resource, index) => (
            <div 
              key={resource._id} 
              className={`knowledge-node node-${index}`}
              style={{
                '--angle': `${(360 / resources.length) * index}deg`,
                '--distance': `${Math.random() * 100 + 150}px`
              }}
            >
              <div className="node-content">
                <div className="node-icon">
                  {resource.url ? <FaGlobe /> : <FaFile />}
                </div>
                <span 
                  className="node-title"
                  onClick={() => fetchAndViewResource(resource._id)}
                >
                  {resource.title || resource.name}
                </span>
                {resource.url && (
                  <a 
                    href={resource.url} 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="node-url"
                  >
                    <FaExternalLinkAlt />
                  </a>
                )}
                <button
                  className="node-delete"
                  onClick={() => handleRemoveResourceEntry(resource.title || resource.name)}
                >
                  <FaTrash />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Upload Status as floating notifications */}
      {Object.keys(uploadStatuses).length > 0 && (
        <div className="upload-status-floating">
          {Object.entries(uploadStatuses).map(([filename, status]) => (
            <UploadStatusItem 
              key={filename} 
              filename={filename} 
              status={status} 
            />
          ))}
        </div>
      )}

      {/* Hidden file inputs */}
      <input
        type="file"
        id="fileInput"
        multiple
        onChange={handleFileSelect}
        style={{ display: 'none' }}
      />
      <input
        type="file"
        id="urlFileInput"
        onChange={handleUrlFileSelect}
        style={{ display: 'none' }}
      />

      {/* Existing modals */}
      {showUpdateModal && (
        <AddResourceModal
          handleUpdateModalClose={handleUpdateModalClose}
          handleUpdateModalSubmit={handleUpdateModalSubmit}
          newTitle={newTitle}
          setNewTitle={setNewTitle}
          newUrl={newUrl}
          setNewUrl={setNewUrl}
        />
      )}
      {overlay.show && <FeedbackOverlay {...overlay} />}
      {fileUrl && <ResourceViewer url={fileUrl} onClose={() => setFileUrl('')} />}
    </div>
  );
};

export default KnowledgeBoard;

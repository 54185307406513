import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Sidebar.css';
import { useTranslation } from 'react-i18next';
import { FaHome, FaUsers, FaShippingFast, FaRobot, FaChartBar, FaArchive, FaUserPlus, FaCog, FaPowerOff, FaGlobe, FaInfoCircle, FaTruck, FaAngleDoubleLeft, FaAngleDoubleRight } from 'react-icons/fa';

const Sidebar = ({ userRole }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);

  const handleLogout = () => {
    sessionStorage.removeItem('access_token');
    navigate('/');
  };

  const toggleSidebar = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={`sidebar ${isExpanded ? 'expanded' : ''}`}>
      <div className="sidebar-header">
        <img src="/menu_logo.png" alt={t('logo')} className="logo" />
      </div>
      <nav className="sidebar-nav">
        <ul>
          {(userRole === 'admin' || userRole === 'employee') && (
            <li>
              <NavLink to="/dashboard">
                <div className="icon"><FaHome /></div>
                <span className="text">{t('dashboard')}</span>
              </NavLink>
            </li>
          )}
          {userRole === 'admin' && (
            <>
              <li>
                <NavLink to="/ai-assistants">
                  <div className="icon"><FaRobot /></div>
                  <span className="text">{t('aiAgentsLabs')}</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/create-agent">
                  <div className="icon"><FaUserPlus /></div>
                  <span className="text">{t('employee_creation')}</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/statistics">
                  <div className="icon"><FaChartBar /></div>
                  <span className="text">{t('statistics')}</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/tickets/archive">
                  <div className="icon"><FaArchive /></div>
                  <span className="text">{t('archive')}</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/parameters">
                  <div className="icon"><FaCog /></div>
                  <span className="text">{t('parameters')}</span>
                </NavLink>
              </li>
            </>
          )}
          {userRole === 'e-commerce' && (
            <>
              <li>
                <NavLink to="/ai-assistants">
                  <div className="icon"><FaRobot /></div>
                  <span className="text">{t('aiAgentsLabs')}</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/create-agent">
                  <div className="icon"><FaUserPlus /></div>
                  <span className="text">{t('employee_creation')}</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/customers">
                  <div className="icon"><FaUsers /></div>
                  <span className="text">{t('customers')}</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/logistician">
                  <div className="icon"><FaShippingFast /></div>
                  <span className="text">{t('logistician_bar')}</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/statistics">
                  <div className="icon"><FaChartBar /></div>
                  <span className="text">{t('statistics')}</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/tickets/archive">
                  <div className="icon"><FaArchive /></div>
                  <span className="text">{t('archive')}</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/parameters">
                  <div className="icon"><FaCog /></div>
                  <span className="text">{t('parameters')}</span>
                </NavLink>
              </li>
            </>
          )}
          {userRole === 'logistics' && (
            <>
              <li>
                <NavLink to="/dashboard-logs">
                  <div className="icon"><FaHome /></div>
                  <span className="text">{t('dashboard')}</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/returns">
                  <div className="icon"><FaTruck /></div>
                  <span className="text">{t('Adminlogistics')}</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/logistics-ai-assistants">
                  <div className="icon"><FaRobot /></div>
                  <span className="text">{t('LogisticsAiAssistants')}</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/logistics-statistics">
                  <div className="icon"><FaChartBar /></div>
                  <span className="text">{t('LogisticsStatistics')}</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/tickets/archive">
                  <div className="icon"><FaArchive /></div>
                  <span className="text">{t('archive')}</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/parameters">
                  <div className="icon"><FaCog /></div>
                  <span className="text">{t('parameters')}</span>
                </NavLink>
              </li>
            </>
          )}
        </ul>
      </nav>
      <div className="sidebar-footer">
        <button onClick={toggleSidebar} className="toggle-button">
          <div className="icon">
            {isExpanded ? <FaAngleDoubleLeft /> : <FaAngleDoubleRight />}
          </div>
          <span className="text">{isExpanded ? t('collapse') : t('expand')}</span>
        </button>
        <button onClick={handleLogout} className="logout-button">
          <div className="icon"><FaPowerOff /></div>
          <span className="text">{t('logout')}</span>
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
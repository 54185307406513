import React, { useState } from 'react';
import axios from 'axios';
import './TicketTableArchive.css';
import { useTranslation } from 'react-i18next';
import { FaInstagram, FaFacebookMessenger, FaWhatsapp, FaEnvelope, FaSort, FaSortUp, FaSortDown, FaSearch } from 'react-icons/fa';

const TicketTableArchive = ({ tickets, user, onTicketClick }) => {
  const { t } = useTranslation();
  const [sortOrder, setSortOrder] = useState('desc');
  const [sortField, setSortField] = useState('created_at');
  const [statusFilter, setStatusFilter] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [initialLoading, setInitialLoading] = useState(true);
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [searchError, setSearchError] = useState(null);

  const channelIcons = {
    Instagram: FaInstagram,
    Messenger: FaFacebookMessenger,
    WhatsApp: FaWhatsapp,
    Gmail: FaEnvelope
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const sortTickets = (tickets, field, order) => {
    return [...tickets].sort((a, b) => {
      const valA = a[field];
      const valB = b[field];
      if (field === 'created_at') {
        return order === 'asc' ? new Date(valA) - new Date(valB) : new Date(valB) - new Date(valA);
      }
      if (field === 'sentiment_score') {
        return order === 'asc' ? (valA || 0) - (valB || 0) : (valB || 0) - (valA || 0);
      }
      return order === 'asc' ? valA.localeCompare(valB) : valB.localeCompare(valA);
    });
  };

  const filterTickets = (tickets) => {
    return tickets.filter(ticket => {
      const statusMatch = statusFilter ? ticket.status === statusFilter : true;
      return statusMatch;
    });
  };

  const handleSearch = async () => {
    if (!searchQuery.trim()) {
      setSearchError(t('ticketTableArchive.enterSearchQuery'));
      return;
    }
    setIsSearching(true);
    setSearchError(null);
    try {
      const token = sessionStorage.getItem('access_token');
      const response = await axios.get('/api/tickets/search/', {
        params: { 
          query: searchQuery,
          sort_field: sortField,
          sort_order: sortOrder
        },
        headers: { Authorization: `Bearer ${token}` }
      });
      
      setSearchResults(response.data);
    } catch (error) {
      console.error('Error searching tickets:', error);
      if (error.response && error.response.status === 500) {
        setSearchError(t('ticketTableArchive.internalServerError'));
      } else if (error.request) {
        setSearchError(t('ticketTableArchive.noResponseReceived'));
      } else {
        setSearchError(t('ticketTableArchive.searchError'));
      }
      setSearchResults([]);
    } finally {
      setIsSearching(false);
    }
  };

  const ticketsToDisplay = searchResults.length > 0 ? searchResults : 
    sortTickets(filterTickets(tickets), sortField, sortOrder);

  const handleSortChange = (field) => {
    setSortField(field);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    if (searchResults.length > 0) {
      handleSearch(); // Re-fetch search results with new sorting
    }
  };

  const handleStatusFilterChange = (e) => {
    setStatusFilter(e.target.value);
  };

  const getSentimentScoreClass = (score) => {
    if (score === null) return 'null';
    if (score <= 25) return 'low';
    if (score <= 60) return 'medium';
    return 'high';
  };

  const SortIcon = ({ field }) => {
    if (sortField !== field) return <FaSort />;
    return sortOrder === 'asc' ? <FaSortUp /> : <FaSortDown />;
  };

  return (
    <div className="unanimity-ticket-archive-wrapper">
      <div className="unanimity-ticket-archive-container">
        <div className="unanimity-ticket-archive-controls">
          <div className="unanimity-ticket-archive-filter">
            <label className="unanimity-ticket-archive-filter-label" htmlFor="status-select">
              {t('ticketTableArchive.status')}:
            </label>
            <select
              id="status-select"
              className="unanimity-ticket-archive-filter-select"
              value={statusFilter}
              onChange={handleStatusFilterChange}
            >
              <option value="">{t('ticketTableArchive.all')}</option>
              <option value="closed">{t('ticketTableArchive.closed')}</option>
              <option value="resolved">{t('ticketTableArchive.resolved')}</option>
              <option value="aiResolved">{t('ticketTableArchive.aiResolved')}</option>
            </select>
          </div>
          <div className="unanimity-ticket-archive-search">
            <input
              type="text"
              className="unanimity-ticket-archive-search-input"
              placeholder={t('ticketTableArchive.enterSearchQuery')}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <button
              className="unanimity-ticket-archive-search-button"
              onClick={handleSearch}
              disabled={isSearching}
            >
              <FaSearch /> {isSearching ? t('ticketTableArchive.searching') : t('ticketTableArchive.search')}
            </button>
          </div>
        </div>

        {searchError && <div className="unanimity-ticket-archive-error">{searchError}</div>}

        <table className="unanimity-ticket-archive-table">
          <thead>
            <tr>
              <th className="unanimity-ticket-archive-table-header">
                {t('ticketTableArchive.channel')}
              </th>
              <th className="unanimity-ticket-archive-table-header">
                {t('ticketTableArchive.subject')}
              </th>
              <th 
                className="unanimity-ticket-archive-table-header sortable"
                onClick={() => handleSortChange('sentiment_score')}
              >
                {t('ticketTableArchive.sentimentScore')} <SortIcon field="sentiment_score" />
              </th>
              <th className="unanimity-ticket-archive-table-header">
                {t('ticketTableArchive.status')}
              </th>
              <th 
                className="unanimity-ticket-archive-table-header sortable"
                onClick={() => handleSortChange('created_at')}
              >
                {t('ticketTableArchive.createdAt')} <SortIcon field="created_at" />
              </th>
            </tr>
          </thead>
          <tbody>
            {ticketsToDisplay.length > 0 ? (
              ticketsToDisplay.map((ticket) => {
                const ChannelIcon = channelIcons[ticket.channel_name];
                return (
                  <tr 
                    key={ticket._id} 
                    className="unanimity-ticket-archive-table-row"
                    onClick={() => onTicketClick(ticket._id)}
                  >
                    <td className="unanimity-ticket-archive-table-cell">
                      {ChannelIcon && <ChannelIcon className="unanimity-ticket-archive-channel-icon" />}
                      <span>{ticket.channel_name}</span>
                    </td>
                    <td className="unanimity-ticket-archive-table-cell">{ticket.subject}</td>
                    <td className="unanimity-ticket-archive-table-cell">
                      <span className={`unanimity-ticket-archive-sentiment unanimity-ticket-archive-sentiment-${getSentimentScoreClass(ticket.sentiment_score)}`}>
                        {ticket.sentiment_score !== null ? Math.round(ticket.sentiment_score) : t('ticketTableArchive.notAvailable')}
                      </span>
                    </td>
                    <td className="unanimity-ticket-archive-table-cell">
                      <span className={`unanimity-ticket-archive-status unanimity-ticket-archive-status-${ticket.status}`}>
                        {t(`ticketTableArchive.${ticket.status}`)}
                      </span>
                    </td>
                    <td className="unanimity-ticket-archive-table-cell">
                      {formatDate(ticket.created_at)}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td 
                  colSpan="5" 
                  className="unanimity-ticket-archive-table-cell unanimity-ticket-archive-no-tickets"
                >
                  {searchResults.length === 0 && searchQuery 
                    ? t('ticketTableArchive.noMatchingTickets') 
                    : t('ticketTableArchive.noTicketsAvailable')}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TicketTableArchive;
// src/components/CreatePDFFormFiller.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  FaFilePdf,
  FaUpload,
  FaTrash,
  FaExclamationTriangle,
  FaSpinner,
  FaCheckCircle,
  FaArrowUp,
  FaCheckSquare,
  FaList,
  FaFont,
  FaEdit,
} from 'react-icons/fa';
import './CreatePDFFormFiller.css'; // We'll create this CSS file next

const CreatePDFFormFiller = () => {
  const { t } = useTranslation();
  const [user, setUser] = useState(undefined);
  const [pdfs, setPdfs] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [deletingId, setDeletingId] = useState(null);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const token = sessionStorage.getItem('access_token');
    if (!token) {
      navigate('/');
      return;
    }

    fetchUserData(token);
  }, [navigate]);

  useEffect(() => {
    if (user) {
      const token = sessionStorage.getItem('access_token');
      fetchPDFs(token);
    }
  }, [user]);

  const fetchUserData = async (token) => {
    try {
      const response = await axios.get('/api/users/user', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUser(response.data);
    } catch (error) {
      console.error('Failed to fetch user data:', error);
      setError(t('createPDFFormFiller.fetchUserError'));
    }
  };

  const fetchPDFs = async (token) => {
    try {
      const response = await axios.get('/api/files/pdf_filler/list', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setPdfs(response.data.pdfs);
    } catch (error) {
      console.error('Failed to fetch PDFs:', error);
      setError(t('createPDFFormFiller.fetchPdfsError'));
    }
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
    setError('');
    setSuccessMessage('');
  };

  const handleUploadPDF = async (e) => {
    e.preventDefault();
    if (!selectedFile) {
      setError(t('createPDFFormFiller.selectFileError'));
      return;
    }

    if (selectedFile.type !== 'application/pdf') {
      setError(t('createPDFFormFiller.invalidFileTypeError'));
      return;
    }

    setUploading(true);
    setError('');
    setSuccessMessage('');

    try {
      const token = sessionStorage.getItem('access_token');
      const formData = new FormData();
      formData.append('pdf_file', selectedFile);

      const response = await axios.post('/api/files/pdf_filler/upload', formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      setSelectedFile(null);
      fetchPDFs(token);
      setSuccessMessage(t('createPDFFormFiller.uploadSuccess'));
    } catch (error) {
      console.error('Failed to upload PDF:', error);
      setError(t('createPDFFormFiller.uploadError'));
    } finally {
      setUploading(false);
    }
  };

  const handleDeletePDF = async (pdfId) => {
    const confirmDelete = window.confirm(t('createPDFFormFiller.confirmDelete'));
    if (!confirmDelete) return;

    setDeletingId(pdfId);
    setError('');
    setSuccessMessage('');

    try {
      const token = sessionStorage.getItem('access_token');
      await axios.delete(`/api/files/pdf_filler/delete/${pdfId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      fetchPDFs(token);
      setSuccessMessage(t('createPDFFormFiller.deleteSuccess'));
    } catch (error) {
      console.error('Failed to delete PDF:', error);
      setError(t('createPDFFormFiller.deleteError'));
    } finally {
      setDeletingId(null);
    }
  };

  const getFieldTypeIcon = (fieldName) => {
    if (fieldName.includes('Check Box')) {
      return <FaCheckSquare />;
    } else if (fieldName.includes('List Box') || fieldName.includes('Combo Box')) {
      return <FaList />;
    } else if (fieldName.includes('Text Box')) {
      return <FaFont />;
    } else {
      return <FaEdit />;
    }
  };

  const formatFieldName = (fieldName) => {
    return fieldName
      .replace(' Text Box', '')
      .replace(' Check Box', '')
      .replace(' List Box', '')
      .replace(' Combo Box', '')
      .replace(' Formatted Field', '');
  };

  const getFieldType = (fieldName) => {
    if (fieldName.includes('Check Box')) {
      return t('createPDFFormFiller.fieldTypes.checkbox');
    } else if (fieldName.includes('List Box')) {
      return t('createPDFFormFiller.fieldTypes.list');
    } else if (fieldName.includes('Combo Box')) {
      return t('createPDFFormFiller.fieldTypes.dropdown');
    } else if (fieldName.includes('Text Box')) {
      return t('createPDFFormFiller.fieldTypes.text');
    } else {
      return t('createPDFFormFiller.fieldTypes.input');
    }
  };

  if (user === undefined) {
    return (
      <div className="pdf-form-dashboard">
        <div className="pdf-form-card-container">
          <div className="loading-spinner">
            <FaSpinner className="spinner" /> {t('createPDFFormFiller.loading')}
          </div>
        </div>
      </div>
    );
  }

  if (user.subscription_plan === 'free') {
    return (
      <div className="pdf-form-dashboard">
        <div className="pdf-form-card-container">
          <div className="dashboard-card create-pdf-no-access">
            <FaExclamationTriangle className="error-icon" />
            <p>{t('createPDFFormFiller.noAccessMessage')}</p>
            <button
              onClick={() => navigate('/Payment')}
              className="upgrade-button"
              aria-label={t('createPDFFormFiller.upgradeNow')}
            >
              {t('createPDFFormFiller.upgradeNow')}
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="pdf-form-dashboard">
      <div className="pdf-form-card-container">

        {/* Feedback Messages */}
        {error && (
          <div className="feedback-message error">
            <FaExclamationTriangle /> {error}
          </div>
        )}
        {successMessage && (
          <div className="feedback-message success">
            <FaCheckCircle /> {successMessage}
          </div>
        )}

        {/* Upload Form */}
        <form onSubmit={handleUploadPDF} className="upload-form">
          <label htmlFor="pdf-upload" className="upload-label">
            <FaUpload className="upload-icon" />
            {t('createPDFFormFiller.selectPDF')}
          </label>
          <input
            type="file"
            id="pdf-upload"
            accept=".pdf"
            onChange={handleFileChange}
            className="upload-input"
            aria-label={t('createPDFFormFiller.selectPDFAriaLabel')}
          />
          <button
            type="submit"
            className="upload-button"
            disabled={uploading}
            aria-label={t('createPDFFormFiller.uploadPDF')}
          >
            {uploading ? (
              <>
                <FaSpinner className="spinner" /> {t('createPDFFormFiller.uploading')}
              </>
            ) : (
              <>
                <FaUpload /> {t('createPDFFormFiller.uploadPDF')}
              </>
            )}
          </button>
        </form>

        {/* PDF List */}
        <div className="pdf-list">
          {pdfs.length > 0 ? (
            pdfs.map((pdf) => (
              <div key={pdf._id} className="pdf-card">
                <div className="pdf-info">
                  <FaFilePdf className="pdf-icon" />
                  <span className="pdf-name">{pdf.pdf_name}</span>
                </div>
                
                <div className="pdf-fields">
                  <h4>{t('createPDFFormFiller.detectedFields')}</h4>
                  <div className="fields-container">
                    {pdf.tool_schema[0]?.function?.parameters?.properties && (
                      Object.entries(pdf.tool_schema[0].function.parameters.properties)
                        .filter(([key]) => key !== 'customer_email') // Exclude customer_email as it's a system field
                        .map(([fieldName, fieldProps]) => (
                          <div key={fieldName} className="field-item">
                            <div className="field-type-icon">
                              {getFieldTypeIcon(fieldName)}
                            </div>
                            <div className="field-details">
                              <span className="field-name">{formatFieldName(fieldName)}</span>
                              <span className="field-type">{getFieldType(fieldName)}</span>
                            </div>
                          </div>
                        ))
                    )}
                  </div>
                </div>
                
                <div className="pdf-actions">
                  <button
                    className="delete-button"
                    onClick={() => handleDeletePDF(pdf._id)}
                    disabled={deletingId === pdf._id}
                    aria-label={t('createPDFFormFiller.deletePDFAriaLabel', { name: pdf.pdf_name })}
                  >
                    {deletingId === pdf._id ? (
                      <FaSpinner className="spinner" />
                    ) : (
                      <FaTrash />
                    )}
                  </button>
                </div>
              </div>
            ))
          ) : (
            <p className="no-pdfs-message">{t('createPDFFormFiller.noPdfs')}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreatePDFFormFiller;

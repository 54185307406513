import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './UserParameter.css';
import { useNavigate, Link } from 'react-router-dom';
import WhatsAppIntegration from './WhatsAppIntegration';
import ChatbotSettings from './ChatbotSettings';
import { useTranslation } from 'react-i18next';
import EndpointTestModal from './EndpointTestModal';
import { FaUser, FaEnvelope, FaLock, FaTrash, FaCog, FaInstagram, FaGoogle, FaWhatsapp, FaRobot, FaWarehouse, FaTruck, FaTimes, FaPlus, FaLink, FaSave, FaCheck, FaCheckCircle, FaExclamationTriangle, FaEdit } from 'react-icons/fa';

const UserParameter = () => {
  const { t } = useTranslation();
  const [user, setUser] = useState(undefined);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [popupMessage, setPopupMessage] = useState('');
  const [isPasswordPopupVisible, setIsPasswordPopupVisible] = useState(false);
  const [isDeletePopupVisible, setIsDeletePopupVisible] = useState(false);
  const [confirmUsername, setConfirmUsername] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showEDNForm, setShowEDNForm] = useState(false);
  const [endpointDetails, setEndpointDetails] = useState({
    endpoint_url: '',
    endpoint_api_key: ''
  });
  const [isEndpointLoading, setIsEndpointLoading] = useState(false);
  const [ednCredentials, setEdnCredentials] = useState({ edn_api_account: '', edn_api_key: '' });
  const [showWhatsAppIntegration, setShowWhatsAppIntegration] = useState(false); // State to show WhatsApp integration
  const [showChatbotSettings, setShowChatbotSettings] = useState(false); // State to show Chatbot Settings popup
  const [instagramAccounts, setInstagramAccounts] = useState([]); // Add this line
  const [isWmsConnecting, setIsWmsConnecting] = useState(false);
  const [carriers, setCarriers] = useState([]);
  const [showCarrierForm, setShowCarrierForm] = useState(false);
  const [selectedCarrier, setSelectedCarrier] = useState(null);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('edn');
  const [testResult, setTestResult] = useState(null);
  const [isEndpointTestModalOpen, setIsEndpointTestModalOpen] = useState(false);
  const [newCarrier, setNewCarrier] = useState({
    name: '',
    email: '',
    contact_rules: ''
  });

  useEffect(() => {
    const token = sessionStorage.getItem('access_token');
    if (!token) {
      navigate('/'); // Redirect if no token found
      return;
    }

    fetchUserData(token);
  }, [navigate]);

  useEffect(() => {
    if (user === null) {
      navigate('/'); // Redirect if user data is explicitly null and not loading
    }
  }, [user, navigate]);

  useEffect(() => {
    fetchCarriers();
  }, []);

  const fetchUserData = async (token) => {
    try {
      const response = await axios.get('/api/users/user', {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.data) {
        setUser({
          username: response.data.username || '',
          firstname: response.data.firstname || '',
          lastname: response.data.lastname || '',
          email: response.data.email || '',
          subscription_plan: response.data.subscription_plan || '',
          user_meta_id: response.data.user_meta_id || '',
          user_insta_id: response.data.user_insta_id || '',
          gmail_email_address: response.data.gmail_email || '',
          automation: response.data.automation || '',
          shop_name: response.data.shop_name || '',
          instagram_username: response.data.insta_username || '',
          whatsapp_phone_number: response.data.whatsapp_phone_number || '',
          edn_api_account: response.data.edn_api_account || '',
          unanimity_wms_id: response.data.unanimity_wms_id || ''
        });
      } else {
        setUser(null); // Ensure user is set to null if fetch fails
      }
    } catch (error) {
      console.error('Failed to fetch user data:', error);
      setUser(null); // Ensure user is set to null on error
    }
  };

  const fetchCarriers = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      const response = await axios.get('/api/logistics/carriers', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setCarriers(response.data);
    } catch (error) {
      console.error('Failed to fetch carriers:', error);
      setPopupMessage('Failed to fetch carriers list.');
    }
  };

  const checkConnectionStatuses = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const instaStatus = urlParams.get('status');
    const googleStatus = urlParams.get('google_status');
    const shopifyStatus = urlParams.get('shopify_status');

    if (instaStatus === 'success') {
      setPopupMessage('Account connected successfully!');
    } else if (instaStatus === 'failure') {
      setPopupMessage('Failed to connect Instagram account.');
    }

    if (googleStatus === 'success') {
      setPopupMessage('Gmail connected successfully!');
    } else if (googleStatus === 'failure') {
      setPopupMessage('Failed to connect Google account.');
    }

    if (shopifyStatus === 'success') {
      setPopupMessage('Shopify store connected successfully!');
    } else if (shopifyStatus === 'failure') {
      setPopupMessage('Failed to connect Shopify account.');
    }
  };


  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmNewPassword) {
      alert('New passwords do not match.');
      return;
    }

    try {
      const token = sessionStorage.getItem('access_token');
      await axios.put('/api/users/update-user', {
        currentPassword,
        password: newPassword,
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setCurrentPassword('');
      setNewPassword('');
      setConfirmNewPassword('');
      alert('Password updated successfully.');
      setIsPasswordPopupVisible(false);
    } catch (error) {
      console.error('Failed to update password:', error);
      alert('Error updating password.');
    }
  };
  const fetchEndpointDetails = async () => {
    setIsEndpointLoading(true);
    const token = sessionStorage.getItem('access_token');
    if (!token) {
      navigate('/');
      return;
    }
    
    try {
      const response = await axios.get('/api/users/user/endpoint', {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.data) {
        setEndpointDetails({
          endpoint_url: response.data.endpoint_url || '',
          endpoint_api_key: response.data.endpoint_api_key || '',
        });
      }
    } catch (error) {
      console.error('Failed to fetch endpoint details:', error);
      setEndpointDetails({ endpoint_url: '', endpoint_api_key: '' });
    } finally {
      setIsEndpointLoading(false);
    }
  };

  const handleUpdateEndpoint = async () => {
    const token = sessionStorage.getItem('access_token');
    try {
      await axios.put('/api/users/user/endpoint', endpointDetails, {
        headers: { Authorization: `Bearer ${token}` },
      });
      alert('Endpoint details updated successfully.');
      fetchEndpointDetails();  // Refresh the data after update
    } catch (error) {
      console.error('Failed to update endpoint details:', error);
      alert('Error updating endpoint details.');
    }
  };
  
  
  useEffect(() => {
    fetchEndpointDetails();
  }, []);
  

  const handleProfileUpdate = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      await axios.put('/api/users/update-user', {
        username: user.username,
        whatsapp_phone_number: user.whatsapp_phone_number
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      alert('Profile updated successfully.');
      fetchUserData(token);
    } catch (error) {
      console.error('Failed to update profile:', error);
      alert('Error updating profile.');
    }
  };

  const handleConnectEDN = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      await axios.post('/api/logistics/edn/connect', ednCredentials, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUser({ ...user, edn_api_account: ednCredentials.edn_api_account });
      setPopupMessage('EDN connected successfully!');
      setShowEDNForm(false);
    } catch (error) {
      console.error('Failed to connect EDN account:', error);
      setPopupMessage('Failed to connect EDN account.');
    }
  };
  
  const handleDisconnectEDN = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      await axios.delete('/api/logistics/edn/disconnect', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUser({ ...user, edn_api_account: '' });
      setPopupMessage('EDN disconnected successfully!');
    } catch (error) {
      console.error('Failed to disconnect EDN account:', error);
      setPopupMessage('Failed to disconnect EDN account.');
    }
  };

  const handleConnectInstagram = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      const response = await axios.get('/api/social/instagram/connect', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      window.location.href = response.data.authorization_url;
    } catch (error) {
      console.error('Failed to connect Instagram account:', error);
    }
  };

  useEffect(() => {
    checkConnectionStatuses();

    // Check if the URL contains Instagram callback parameters
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const state = urlParams.get('state');
    if (code && state) {
      handleInstagramCallback(code, state);
    }
  }, []);

  const handleInstagramCallback = async (code, state) => {
    const data = await fetchInstagramAccounts(code, state);
    if (data) {
      setInstagramAccounts(data.accounts);
    }
  };

  const fetchInstagramAccounts = async (code, state) => {
    try {
      const response = await axios.get(`/api/social/instagram/callback?code=${code}&state=${state}`);
      return response.data;
    } catch (error) {
      console.error('Failed to fetch Instagram accounts:', error);
      return null;
    }
  };

  const handleAccountSelect = async (accountId, accountName) => {
    try {
      const token = sessionStorage.getItem('access_token');
      if (!token) {
        alert('You are not logged in. Please log in again.');
        navigate('/login');
        return;
      }

      const payload = {
        account_id: accountId,
        name: accountName
      };

      console.log('Sending payload:', payload);

      await axios.post('/api/social/instagram/select_account', payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      navigate('/parameters?status=success');
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert('Session expired. Please log in again.');
        navigate('/login');
      } else {
        console.error('Failed to select Instagram account:', error);
        alert('Failed to connect Instagram account.');
      }
    }
  };

  const handleConnectGoogle = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      const response = await axios.get('/api/social/google/connect', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      window.location.href = response.data.authorization_url;
    } catch (error) {
      console.error('Failed to connect Google account:', error);
      setPopupMessage('Failed to connect Google account.');
    }
  };

  const togglePasswordPopup = () => {
    setIsPasswordPopupVisible(!isPasswordPopupVisible);
  };

  const handleDisconnectInstagram = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      await axios.delete('/api/social/instagram/unsubscribe', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUser({ ...user, user_insta_id: '', instagram_username: '' });
      setPopupMessage('Instagram account disconnected successfully!');
    } catch (error) {
      console.error('Failed to disconnect Instagram account:', error);
      setPopupMessage('Failed to disconnect Instagram account.');
    }
  };

  const handleDisconnectGoogle = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      await axios.delete('/api/social/google/unsubscribe', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUser({ ...user, gmail_email_address: '' });
      setPopupMessage('Google account disconnected successfully!');
    } catch (error) {
      console.error('Failed to disconnect Google account:', error);
      setPopupMessage('Failed to disconnect Google account.');
    }
  };
  const handleDisconnectWhatsApp = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      await axios.delete('/api/social/disconnect/whatsappbusiness', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUser({ ...user, whatsapp_phone_number: '' });
      setPopupMessage('WhatsApp account disconnected successfully!');
    } catch (error) {
      console.error('Failed to disconnect WhatsApp account:', error);
      setPopupMessage('Failed to disconnect WhatsApp account.');
    }
  };

  const handleDeleteUser = async () => {
    if (confirmUsername !== user.username) {
      alert('Username does not match.');
      return;
    }

    try {
      const token = sessionStorage.getItem('access_token');
      await axios.delete('/api/users/user/delete', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      sessionStorage.removeItem('access_token');
      navigate('/');
    } catch (error) {
      console.error('Failed to delete user account:', error);
      alert('Error deleting user account.');
    }
  };

  const toggleDeletePopup = () => {
    setIsDeletePopupVisible(!isDeletePopupVisible);
  };
  const handleCloseWhatsAppIntegration = () => {
    setShowWhatsAppIntegration(false);
  };

  const handleConnectWhatsApp = () => {
    setShowWhatsAppIntegration(true); // Show the WhatsApp integration component
  };

  const handleOpenChatbotSettings = () => {
    setShowChatbotSettings(true); // Show the Chatbot Settings component
  };

  const handleCloseChatbotSettings = () => {
    setShowChatbotSettings(false); // Hide the Chatbot Settings component
  };

  const handleConnectWMS = async () => {
    try {
      setIsWmsConnecting(true);
      const token = sessionStorage.getItem('access_token');
      await axios.post('/api/users/associate-wms', null, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setPopupMessage('Successfully connected to Unanimity WMS!');
      // Refresh user data to get updated WMS status
      fetchUserData(token);
    } catch (error) {
      console.error('Failed to connect to WMS:', error);
      if (error.response?.status === 403) {
        setPopupMessage('Only admin and logistics users can connect to WMS.');
      } else {
        setPopupMessage('Failed to connect to Unanimity WMS.');
      }
    } finally {
      setIsWmsConnecting(false);
    }
  };

  const handleCreateCarrier = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      const response = await axios.post('/api/carriers', newCarrier, {
        headers: { Authorization: `Bearer ${token}` },
      });
      
      // Add new carrier to list
      setCarriers([...carriers, response.data]);
      // Reset form
      setNewCarrier({ name: '', email: '', contact_rules: '' });
      setShowCarrierForm(false);
    } catch (error) {
      console.error('Failed to create carrier:', error);
    }
  };

  const renderCarriersSection = () => (
    <div className="carriers-section">      
      <div className="carriers-table-container">
        <table className="carriers-table">
          <thead>
            <tr>
              <th>{t('carriers.name')}</th>
              <th>{t('carriers.email')}</th>
              <th>{t('carriers.contactRules')}</th>
              {user?.role === 'logistics' && <th>{t('carriers.actions')}</th>}
            </tr>
          </thead>
          <tbody>
            {carriers.map(carrier => (
              <tr key={carrier.id}>
                <td>{carrier.name}</td>
                <td>{carrier.email}</td>
                <td>{carrier.contact_rules}</td>
                {user?.role === 'logistics' && (
                  <td className="actions-cell">
                    <button 
                      className="icon-button edit"
                      onClick={() => handleEditCarrier(carrier)}
                      title={t('carriers.edit')}
                    >
                      <FaEdit />
                    </button>
                    <button 
                      className="icon-button delete"
                      onClick={() => handleDeleteCarrier(carrier.id)}
                      title={t('carriers.delete')}
                    >
                      <FaTrash />
                    </button>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {user?.role === 'logistics' && (
        <button 
          className="add-carrier-button"
          onClick={() => setShowCarrierForm(true)}
        >
          <FaPlus /> {t('carriers.addNew')}
        </button>
      )}
    </div>
  );

  const handleSaveEndpoint = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      await axios.post('/api/endpoint/configure', endpointDetails, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      setTestResult({
        success: true,
        message: t('endpointIntegration.form.saveSuccess')
      });
    } catch (error) {
      console.error('Failed to save endpoint:', error);
      setTestResult({
        success: false,
        message: t('endpointIntegration.form.saveError')
      });
    }
  };

  const handleTestEndpoint = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      await axios.post('/api/endpoint/test', endpointDetails, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      setTestResult({
        success: true,
        message: t('endpointIntegration.form.testSuccess')
      });
    } catch (error) {
      console.error('Failed to test endpoint:', error);
      setTestResult({
        success: false,
        message: t('endpointIntegration.form.testError')
      });
    }
  };

  // Add this function to fetch endpoint configuration
  const fetchEndpointConfig = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      const response = await axios.get('/api/endpoint/configuration', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      
      if (response.data) {
        setEndpointDetails({
          endpoint_url: response.data.endpoint_url || '',
          endpoint_api_key: response.data.endpoint_api_key || ''
        });
      }
    } catch (error) {
      console.error('Failed to fetch endpoint configuration:', error);
    }
  };

  // Add this to useEffect to fetch data when component mounts
  useEffect(() => {
    if (user) {
      fetchEndpointConfig();
    }
  }, [user]);

  // Add these handler functions
  const handleEditCarrier = (carrier) => {
    setSelectedCarrier(carrier);
    setShowCarrierForm(true);
  };

  const handleDeleteCarrier = async (carrierId) => {
    if (window.confirm(t('carriers.deleteConfirm'))) {
      try {
        const token = sessionStorage.getItem('access_token');
        await axios.delete(`/api/carriers/${carrierId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        
        // Update carriers list
        setCarriers(carriers.filter(c => c.id !== carrierId));
      } catch (error) {
        console.error('Failed to delete carrier:', error);
      }
    }
  };

  // Add this to fetch carriers when component mounts
  useEffect(() => {
    const fetchCarriers = async () => {
      try {
        const token = sessionStorage.getItem('access_token');
        const response = await axios.get('/api/carriers', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setCarriers(response.data);
      } catch (error) {
        console.error('Failed to fetch carriers:', error);
      }
    };

    if (user) {
      fetchCarriers();
    }
  }, [user]);

  return (
    <div className="user-parameter-container">
      {user === undefined ? (
        <div className="loading">{t('userParameter.loading')}</div>
      ) : user ? (
        <div className="user-parameter-content">
          {/* Left Column */}
          <div className="user-parameter-left">
            <div className="user-info-section">
              <h2 className="section-title"><FaUser /> {t('userParameter.userInfo')}</h2>
              <div className="user-info-grid">
                <div className="info-item">
                  <FaUser className="info-icon" />
                  <div>
                    <strong>{t('userParameter.username')}</strong>
                    <p>{user.username}</p>
                  </div>
                </div>
                <div className="info-item">
                  <FaEnvelope className="info-icon" />
                  <div>
                    <strong>{t('userParameter.email')}</strong>
                    <p>{user.email}</p>
                  </div>
                </div>
              </div>
              <button className="change-password-button" onClick={togglePasswordPopup}>
                <FaLock /> {t('userParameter.changePassword')}
              </button>
            </div>

            <div className="integrations-section">
              <h2 className="section-title"><FaCog /> {t('userParameter.integrations')}</h2>
              <div className="integration-buttons">
                <IntegrationButton
                  icon={<FaInstagram />}
                  label="Instagram"
                  connected={!!user.user_insta_id}
                  onConnect={handleConnectInstagram}
                  onDisconnect={handleDisconnectInstagram}
                  className="instagram"
                />
                <IntegrationButton
                  icon={<FaGoogle />}
                  label="Google"
                  connected={!!user.gmail_email_address}
                  onConnect={handleConnectGoogle}
                  onDisconnect={handleDisconnectGoogle}
                  className="google"
                />
                <IntegrationButton
                  icon={<FaWhatsapp />}
                  label="WhatsApp"
                  connected={!!user.whatsapp_phone_number}
                  onConnect={handleConnectWhatsApp}
                  onDisconnect={handleDisconnectWhatsApp}
                  className="whatsapp"
                />
                <IntegrationButton
                  icon={<FaRobot />}
                  label="Chatbot"
                  connected={true}
                  onConnect={() => navigate('/chatbot-settings')}
                  onDisconnect={() => navigate('/chatbot-settings')}
                  className="chatbot"
                />
                <IntegrationButton
                  icon={<FaWarehouse />}
                  label="Unanimity WMS"
                  connected={!!user.unanimity_wms_id}
                  onConnect={handleConnectWMS}
                  onDisconnect={() => {}}
                  loading={isWmsConnecting}
                  className="wms"
                />
              </div>
            </div>

            <div className="danger-zone">
              <h2 className="section-title">{t('userParameter.dangerZone')}</h2>
              <button className="delete-account-button" onClick={toggleDeletePopup}>
                <FaTrash /> {t('userParameter.deleteAccount')}
              </button>
            </div>
          </div>

          {/* Right Column */}
          <div className="user-parameter-right">
            <div className="tabs-container">
              <div className="tabs-header">
                <button 
                  className={`tab-button ${activeTab === 'edn' ? 'active' : ''}`}
                  onClick={() => setActiveTab('edn')}
                >
                  TMS Integration
                </button>
                <button 
                  className={`tab-button ${activeTab === 'endpoint' ? 'active' : ''}`}
                  onClick={() => setActiveTab('endpoint')}
                >
                  WMS Integration
                </button>
                <button 
                  className={`tab-button ${activeTab === 'carriers' ? 'active' : ''}`}
                  onClick={() => setActiveTab('carriers')}
                >
                  Carriers Integration
                </button>
              </div>

              <div className="tab-content">
                {activeTab === 'edn' && (
                  <div className="edn-integration-section">
                    <div className="edn-status">
                      <p>{t('ednIntegration.description')}</p>
                      {user.edn_api_account ? (
                        <>
                          <p>
                            {t('ednIntegration.connected')}
                            <span className="edn-account">{user.edn_api_account}</span>
                          </p>
                          <button 
                            className="edn-button edn-disconnect" 
                            onClick={handleDisconnectEDN}
                          >
                            <FaTimes /> {t('ednIntegration.disconnect')}
                          </button>
                        </>
                      ) : (
                        <button 
                          className="edn-button edn-connect" 
                          onClick={() => setShowEDNForm(true)}
                        >
                          <FaPlus /> {t('ednIntegration.connect')}
                        </button>
                      )}
                    </div>
                  </div>
                )}
                {activeTab === 'endpoint' && (
                  <div className="endpoint-section">
                    {/* Basic Configuration */}
                    <div className="endpoint-form">
                      <h3>{t('endpointIntegration.basicConfig')}</h3>
                      <div className="endpoint-form-group">
                        <label htmlFor="endpoint-url">
                          {t('endpointIntegration.form.url')}
                        </label>
                        <input
                          type="text"
                          id="endpoint-url"
                          value={endpointDetails.endpoint_url}
                          onChange={(e) => setEndpointDetails({
                            ...endpointDetails,
                            endpoint_url: e.target.value
                          })}
                          placeholder="https://"
                        />
                      </div>

                      <div className="endpoint-form-group">
                        <label htmlFor="endpoint-api-key">
                          {t('endpointIntegration.form.apiKey')}
                        </label>
                        <input
                          type="password"
                          id="endpoint-api-key"
                          value={endpointDetails.endpoint_api_key}
                          onChange={(e) => setEndpointDetails({
                            ...endpointDetails,
                            endpoint_api_key: e.target.value
                          })}
                          placeholder="••••••••"
                        />
                      </div>

                      <div className="endpoint-actions">
                        <button 
                          className="endpoint-button primary"
                          onClick={handleSaveEndpoint}
                        >
                          <FaSave /> {t('endpointIntegration.form.save')}
                        </button>
                        <button 
                          className="endpoint-button secondary"
                          onClick={handleTestEndpoint}
                          disabled={!endpointDetails.endpoint_url || !endpointDetails.endpoint_api_key}
                        >
                          <FaCheck /> {t('endpointIntegration.form.testEndpoint')}
                        </button>
                      </div>

                      {testResult && (
                        <div className={`endpoint-test-result ${testResult.success ? 'success' : 'error'}`}>
                          <span>{testResult.success ? 
                            <FaCheckCircle /> : 
                            <FaExclamationTriangle />
                          }</span>
                          <span>{testResult.message}</span>
                        </div>
                      )}
                    </div>

                    {/* Advanced Testing Section */}
                    <div className="endpoint-advanced-testing">
                      <h3>{t('endpointIntegration.advancedTesting')}</h3>
                      <EndpointTestModal 
                        isOpen={true} 
                        onClose={() => {}} 
                        embedded={true}
                      />
                    </div>
                  </div>
                )}
                {activeTab === 'carriers' && (
                  <div className="carriers-section">
                    {renderCarriersSection()}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="error">{t('userParameter.loadError')}</div>
      )}

      {/* Modals */}
      {isPasswordPopupVisible && (
        <Modal onClose={togglePasswordPopup} title={t('userParameter.changePassword')}>
          <form onSubmit={handlePasswordSubmit} className="password-form">
            <div className="form-group">
              <label htmlFor="current-password">{t('userParameter.currentPassword')}</label>
              <input
                type="password"
                id="current-password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="new-password">{t('userParameter.newPassword')}</label>
              <input
                type="password"
                id="new-password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="confirm-new-password">{t('userParameter.confirmNewPassword')}</label>
              <input
                type="password"
                id="confirm-new-password"
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
                required
              />
            </div>
            <div className="form-actions">
              <button type="submit" className="primary-button">
                {t('userParameter.updatePassword')}
              </button>
            </div>
          </form>
        </Modal>
      )}

      {isDeletePopupVisible && (
        <Modal onClose={toggleDeletePopup} title={t('userParameter.deleteAccount')}>
          <form onSubmit={handleDeleteUser} className="delete-account-form">
            <p className="warning-text">{t('userParameter.deleteAccountWarning')}</p>
            <div className="form-group">
              <label htmlFor="confirm-username">{t('userParameter.confirmUsername')}</label>
              <input
                type="text"
                id="confirm-username"
                value={confirmUsername}
                onChange={(e) => setConfirmUsername(e.target.value)}
                required
              />
            </div>
            <div className="form-actions">
              <button type="submit" className="danger-button">
                {t('userParameter.confirmDelete')}
              </button>
            </div>
          </form>
        </Modal>
      )}

      {showWhatsAppIntegration && (
        <Modal onClose={handleCloseWhatsAppIntegration} title={t('userParameter.whatsAppIntegration')}>
          <WhatsAppIntegration />
        </Modal>
      )}

      {showEDNForm && (
        <Modal onClose={() => setShowEDNForm(false)} title={t('ednIntegration.form.title')}>
          <div className="edn-form">
            <div className="form-group">
              <label htmlFor="edn-api-account">
                {t('ednIntegration.form.apiAccount')}
              </label>
              <input
                type="text"
                id="edn-api-account"
                value={ednCredentials.edn_api_account}
                onChange={(e) => setEdnCredentials({ 
                  ...ednCredentials, 
                  edn_api_account: e.target.value 
                })}
              />
            </div>
            <div className="form-group">
              <label htmlFor="edn-api-key">
                {t('ednIntegration.form.apiKey')}
              </label>
              <input
                type="password"
                id="edn-api-key"
                value={ednCredentials.edn_api_key}
                onChange={(e) => setEdnCredentials({ 
                  ...ednCredentials, 
                  edn_api_key: e.target.value 
                })}
              />
            </div>
            <button 
              className="edn-button edn-connect" 
              onClick={handleConnectEDN}
            >
              {t('ednIntegration.form.submit')}
            </button>
          </div>
        </Modal>
      )}

      {showChatbotSettings && (
        <Modal onClose={handleCloseChatbotSettings} title={t('userParameter.chatbotSettings')}>
          <ChatbotSettings />
        </Modal>
      )}
    </div>
  );
};

const IntegrationButton = ({ icon, label, connected, onConnect, onDisconnect, loading, className }) => {
  const handleClick = connected ? onDisconnect : onConnect;
  
  return (
    <div className="channel-wrapper">
      <button 
        className={`channel-circle ${className} ${connected ? 'connected' : ''}`} 
        onClick={handleClick} 
        disabled={loading}
      >
        {icon}
      </button>
      <span className="channel-label">{label}</span>
    </div>
  );
};

const Modal = ({ children, onClose, title }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content large-modal"> {/* Add 'large-modal' class */}
        <h2>{title}</h2>
        <button className="close-button-circle" onClick={onClose}></button>
        {children}
      </div>
    </div>
  );
};

export default UserParameter;
// src/components/CreateForm.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './CreateForm.css';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  FaWpforms,
  FaPlus,
  FaTrash,
  FaTimes,
  FaExclamationTriangle,
  FaCheckCircle,
  FaSpinner,
  FaCheck,
} from 'react-icons/fa';

const CreateForm = () => {
  const { t } = useTranslation();
  const [user, setUser] = useState(undefined);
  const [title, setTitle] = useState('');
  const [questions, setQuestions] = useState([{ label: '', placeholder: '' }]);
  const [forms, setForms] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [deletingId, setDeletingId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token = sessionStorage.getItem('access_token');
    if (!token) {
      navigate('/');
      return;
    }

    fetchUserData(token);
  }, [navigate]);

  useEffect(() => {
    if (user) {
      const token = sessionStorage.getItem('access_token');
      fetchForms(token);
    }
  }, [user]);

  const fetchUserData = async (token) => {
    try {
      const response = await axios.get('/api/users/user', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUser(response.data);
    } catch (error) {
      console.error('Failed to fetch user data:', error);
      setError(t('createForm.fetchUserError'));
      setUser(null);
      setLoading(false);
    }
  };

  const fetchForms = async (token) => {
    try {
      const response = await axios.get('/api/forms/list', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setForms(response.data.forms);
      setLoading(false);
    } catch (error) {
      console.error('Failed to fetch forms:', error);
      setError(t('createForm.fetchFormsError'));
      setLoading(false);
    }
  };

  const handleAddQuestion = () => {
    setQuestions([...questions, { label: '', placeholder: '' }]);
  };

  const handleRemoveQuestion = (index) => {
    const newQuestions = questions.filter((_, i) => i !== index);
    setQuestions(newQuestions);
  };

  const handleQuestionChange = (index, key, value) => {
    const newQuestions = questions.map((question, i) =>
      i === index ? { ...question, [key]: value } : question
    );
    setQuestions(newQuestions);
  };

  const handleCreateForm = async (e) => {
    e.preventDefault();
    if (title.trim() === '') {
      setError(t('createForm.titleRequired'));
      return;
    }
    const invalidQuestion = questions.find(q => q.label.trim() === '');
    if (invalidQuestion) {
      setError(t('createForm.questionLabelRequired'));
      return;
    }
    try {
      const token = sessionStorage.getItem('access_token');
      await axios.post(
        '/api/forms/create/',
        {
          form_schema: {
            title,
            fields: questions.map(q => ({
              type: 'text',
              label: q.label,
              placeholder: q.placeholder,
            })),
          },
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setTitle('');
      setQuestions([{ label: '', placeholder: '' }]);
      fetchForms(token);
      setShowPopup(false);
      setSuccessMessage(t('createForm.createSuccess'));
      setError('');
    } catch (error) {
      console.error('Failed to create form:', error);
      setError(t('createForm.createError'));
    }
  };

  const handleDeleteForm = async (formId) => {
    const confirmDelete = window.confirm(t('createForm.confirmDelete'));
    if (!confirmDelete) return;

    try {
      setDeletingId(formId);
      const token = sessionStorage.getItem('access_token');
      await axios.delete(`/api/forms/delete/${formId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      fetchForms(token);
      setSuccessMessage(t('createForm.deleteSuccess'));
    } catch (error) {
      console.error('Failed to delete form:', error);
      setError(t('createForm.deleteError'));
    } finally {
      setDeletingId(null);
    }
  };

  const handleFormClick = (formId) => {
    navigate(`/form-result/${formId}`);
  };

  const togglePopup = () => {
    setShowPopup(!showPopup);
    setError('');
    setSuccessMessage('');
  };

  const closePopup = () => {
    setShowPopup(false);
    setTitle('');
    setQuestions([{ label: '', placeholder: '' }]);
    setError('');
    setSuccessMessage('');
  };

  if (loading) {
    return (
      <div className="create-form-dashboard">
        <div className="loading-spinner">
          <FaSpinner className="spinner" /> {t('createForm.loading')}
        </div>
      </div>
    );
  }

  if (user === null) {
    return (
      <div className="create-form-dashboard">
        <div className="error-message">
          <FaExclamationTriangle /> {t('createForm.userFetchError')}
        </div>
      </div>
    );
  }

  if (user.subscription_plan === 'free') {
    return (
      <div className="create-form-dashboard">
        <div className="dashboard-card create-form-no-access">
          <FaExclamationTriangle className="error-icon" />
          <p>{t('createForm.noAccessMessage')}</p>
          <button
            onClick={() => navigate('/Payment')}
            className="upgrade-button"
            aria-label={t('createForm.upgradeNow')}
          >
            {t('createForm.upgradeNow')}
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="create-form-dashboard">
      <div className="dashboard-grid">
        {/* Left Panel - Forms List */}
        <div className="forms-panel">
          
          <div className="forms-container">
            {forms.map((form) => (
              <div 
                key={form.form_id} 
                className="form-card"
                onClick={() => handleFormClick(form.form_id)}
              >
                <div className="form-card-content">
                  <div className="form-card-header">
                    <FaWpforms className="form-icon" />
                    <h3>{form.title}</h3>
                  </div>
                  <div className="form-card-stats">
                    <div className="stat">
                      <span className="stat-value">{form.responses || 0}</span>
                      <span className="stat-label">{t('createForm.responses')}</span>
                    </div>
                    <div className="stat">
                      <span className="stat-value">{form.fields?.length || 0}</span>
                      <span className="stat-label">{t('createForm.fields')}</span>
                    </div>
                  </div>
                  <button
                    className="delete-button"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteForm(form.form_id);
                    }}
                  >
                    {deletingId === form.form_id ? (
                      <FaSpinner className="spinner" />
                    ) : (
                      <FaTrash />
                    )}
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Right Panel - Form Creation */}
        <div className="creation-panel">
          <h2 className="panel-title">
            <FaPlus className="panel-icon" />
            {t('createForm.createNew')}
          </h2>
          
          <form onSubmit={handleCreateForm} className="creation-form">
            <div className="form-group">
              <label htmlFor="title">
                {t('createForm.formTitle')}
                <span className="required">*</span>
              </label>
              <input
                type="text"
                id="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder={t('createForm.enterFormTitle')}
              />
            </div>

            <div className="questions-container">
              {questions.map((question, index) => (
                <div key={index} className="question-card">
                  <div className="question-header">
                    <span className="question-number">#{index + 1}</span>
                    <button
                      type="button"
                      className="remove-question"
                      onClick={() => handleRemoveQuestion(index)}
                    >
                      <FaTrash />
                    </button>
                  </div>
                  
                  <div className="question-fields">
                    <div className="form-group">
                      <label>
                        {t('createForm.questionLabel')}
                        <span className="required">*</span>
                      </label>
                      <input
                        type="text"
                        value={question.label}
                        onChange={(e) => handleQuestionChange(index, 'label', e.target.value)}
                        placeholder={t('createForm.enterQuestion')}
                      />
                    </div>
                    <div className="form-group">
                      <label>{t('createForm.placeholderLabel')}</label>
                      <input
                        type="text"
                        value={question.placeholder}
                        onChange={(e) => handleQuestionChange(index, 'placeholder', e.target.value)}
                        placeholder={t('createForm.enterPlaceholder')}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="form-actions">
              <button
                type="button"
                className="add-question-button"
                onClick={handleAddQuestion}
              >
                <FaPlus /> {t('createForm.addQuestion')}
              </button>
              <button type="submit" className="submit-button">
                <FaCheck /> {t('createForm.createForm')}
              </button>
            </div>
          </form>

          {error && (
            <div className="error-message">
              <FaExclamationTriangle /> {error}
            </div>
          )}
          {successMessage && (
            <div className="success-message">
              <FaCheckCircle /> {successMessage}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateForm;
